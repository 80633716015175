import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { VoidManualInvoiceModel } from './voidManualInvoice.model';
import { QueryResultsModel } from '../../_base/crud';
import { QueryVoidManualInvoiceModel } from './queryvoidManualInvoice.model';
import * as FileSaver from 'file-saver';
import { environment } from '../../../../environments/environment';

const API_VoidManualInvoice_URL = `${environment.baseAPI}/api/voidManualInvoice`;
const API_EXCEL = `${environment.baseAPI}/api/excel/voidManualInvoice/export`;
const API_BASE = `${environment.baseAPI}/api/voidManualInvoice`;

@Injectable({
	providedIn: 'root'
})
export class VoidManualInvoiceService {
	constructor(private http: HttpClient) { }
	// get list VoidManualInvoice group
	getListVoidManualInvoice(queryParams: any): Observable<QueryResultsModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		let options = {
			params: JSON.stringify(queryParams)
		}
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key])
		}

		// params.set("param", JSON.stringify(queryParams));
		const url = `${API_BASE}/listVoidManualInvoice?${params}`;
		return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
	}

	getAllNum(queryParams: any): Observable<QueryResultsModel> {
		const httpHeaders = new HttpHeaders();
		let params = new URLSearchParams();
		params.set("param", JSON.stringify(queryParams));
		return this.http.get<any>(`${API_BASE}/listNum?${params}`, { headers: httpHeaders });
	}

	getListFalseVoidManualInvoice(queryParams: QueryVoidManualInvoiceModel): Observable<QueryResultsModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		let options = {
			param: JSON.stringify(queryParams)
		}
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key])
		}
		return this.http.get<QueryResultsModel>(API_VoidManualInvoice_URL + '/falselist?' + params, { headers: httpHeaders });
	}
	deleteVoidManualInvoice(voidManualInvoiceId: string) {
		const url = `${API_VoidManualInvoice_URL}/delete/${voidManualInvoiceId}`;
		return this.http.delete(url);
	}
	exportExcel() {
		return FileSaver.saveAs(`${API_EXCEL}`, "export-voidManualInvoice.xlsx");
	}
	updateVoidManualInvoice(voidManualInvoice: VoidManualInvoiceModel) {
		const url = `${API_VoidManualInvoice_URL}/edit/${voidManualInvoice._id}`;
		return this.http.patch(url, voidManualInvoice);
	}
	createVoidManualInvoice(voidManualInvoice: VoidManualInvoiceModel): Observable<VoidManualInvoiceModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<VoidManualInvoiceModel>(`${API_VoidManualInvoice_URL}/add`, voidManualInvoice, { headers: httpHeaders });
	}
	findVoidManualInvoiceByParent(id: string): Observable<QueryResultsModel> {
		return this.http.get<QueryResultsModel>(`${API_VoidManualInvoice_URL}/parent/${id}`);
	}


	findVoidManualInvoiceById(id: any): Observable<any> {
		return this.http.get<any>(`${API_VoidManualInvoice_URL}/${id}`);
	}

	viewVoidManualInvoiceById(queryParams): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		let options = {
			params: JSON.stringify(queryParams)
		}
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key])
		}
		return this.http.get<any>(`${API_BASE}/viewVoidManualInvoice?${params}`);
	}

	downloadVoidManualInvoiceById(queryParams): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		let options = {
			params: JSON.stringify(queryParams)
		}
		let params = new URLSearchParams();
		for (let key in options) {
			params.set(key, options[key])
		}
		return this.http.get<any>(`${API_BASE}/downloadPDF?${params}`);
	}

	generateVoidManualInvoiceCode(): Observable<QueryResultsModel> {
		const url = `${API_VoidManualInvoice_URL}/generate/voidManualInvoice`;
		return this.http.get<QueryResultsModel>(url);
	}

	generateARCard(): Observable<VoidManualInvoiceModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<VoidManualInvoiceModel>(`${API_BASE}/generate`, { headers: httpHeaders });
	}

	findVoidManualInvoiceByIdPlain(id: any): Observable<any> {
		return this.http.get<any>(`${API_VoidManualInvoice_URL}/${id}`);
	}

	// ========================= VOID BILLING # START =========================
	deleteBillVoid(dataSend) {
		const formData: FormData = new FormData();

		if (dataSend.attachment.length) {
			for (let i = 0; i < dataSend.attachment.length; i++) {
				formData.append("attachment", dataSend.attachment[i]);
			}
		} else {
			formData.append("attachment", null);
		}

		formData.append('reasonDel', dataSend.reasonDel);
		formData.append('updateBy', dataSend.updateBy);
		formData.append('descriptionDelete', dataSend.descriptionDelete);

		const url = `${API_BASE}/delete/${dataSend._id}`;


		const req = new HttpRequest('DELETE', `${url}`, formData, {
			reportProgress: true,
			responseType: 'json'
		});

		return this.http.request(req);
	}
	// ========================= VOID BILLING # END =========================

	/**
	 * Export Sample Import
	 * @returns 
	 */
	exportExample(filter) {
		return FileSaver.saveAs(`${API_BASE}/export/sample?startDate=${filter.startDate}&endDate=${filter.endDate}&unit=${filter.unit}&statusValue=${filter.statusValue}`, "export-voidManualInvoice-sample.xlsx");
	}

	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead
			// Let the app keep running by returning an empty result.
			return of(result);
		};
	}
}
