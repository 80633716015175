// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// Material
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator, MatSort, MatSnackBar, MatDialog, MatDatepicker, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material";
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, take, delay } from "rxjs/operators";
import { fromEvent, merge, Observable, of, Subscription } from "rxjs";
// LODASH
import { each, find } from "lodash";
// NGRX
import { Store, select } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";

//services
import { LayoutUtilsService, MessageType, QueryParamsModel } from "../../../../core/_base/crud";

import { BillingModel } from "../../../../core/billing/billing.model";
import { BillingDeleted, BillingGeneratedPageRequested, BillingPageRequested } from "../../../../core/billing/billing.action";
import { BillingDatasource } from "../../../../core/billing/billing.datasource";
import { SubheaderService } from "../../../../core/_base/layout";
import { BillingService } from "../../../../core/billing/billing.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QueryBillingModel } from "../../../../core/billing/querybilling.model";
import { environment } from "../../../../../environments/environment";

import { TemplatePDFBilling } from "../../../../core/templatePDF/billing.service";

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

const appHost = `${location.protocol}//${location.host}`;

import { default as _rollupMoment, Moment } from "moment";
import * as _moment from "moment";

import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ServiceFormat } from "../../../../core/serviceFormat/format.service";

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
(<any>pdfMake).fonts = {
	Poppins: {
		normal: `${appHost}/assets/fonts/poppins/regular.ttf`,
		bold: `${appHost}/assets/fonts/poppins/bold.ttf`,
		italics: `${appHost}/assets/fonts/poppins/italics.ttf`,
		bolditalics: `${appHost}/assets/fonts/poppins/bolditalics.ttf`,
	},
};

const moment = _rollupMoment || _moment;

const MY_FORMATS = {
	parse: {
		dateInput: "MM-YYYY",
	},
	display: {
		dateInput: "MM-YYYY",
		monthYearLabel: "YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "YYYY",
	},
};

@Component({
	selector: "kt-list-process-billing",
	templateUrl: "./list-process-billing.component.html",
	styleUrls: ["./list-process-billing.component.scss"],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class ListProcessUnittypeComponent implements OnInit, OnDestroy {
	file;
	dataSource: BillingDatasource;
	// displayedColumns = ["generated_date", "success", "failed", "generated_by", "actions"];
	displayedColumns = ["generated_date", "success", "failed", "generated_by"];

	periode_date = new Date();
	dateGenerateBilling: string = "";

	loadinggenerate: boolean = false;
	msgErrorGenerate: string = "";
	isGenerateBilling: string = "";

	selectedIndex = 2; // Set default selected index if needed

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild("sort1", { static: true }) sort: MatSort;
	// Filter fields
	// @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
	lastQuery: QueryParamsModel;
	// Selection
	selection = new SelectionModel<BillingModel>(true, []);
	billingResult: BillingModel[] = [];
	data = localStorage.getItem("currentUser");
	dataUser = JSON.parse(this.data);
	billing = this.dataUser.role;
	loadingbilling: boolean = false;

	checkClear: boolean = false;

	valPayDate: string = "";

	isGenerate: boolean = false;
	// Subscriptions
	featureAccess = null;
	filterByStatus: any = [
		{
			payment: "Full Payment",
			value: "full-payment",
		},
		{
			payment: "Outstanding",
			value: "outstanding",
		},
		{
			payment: "Bayar Lebih",
			value: "bayar-lebih",
		},
		{
			payment: "Bayar Kurang",
			value: "bayar-kurang",
		},
	];

	private intervalId: any;

	date = {
		valid: false,
		filter: {
			control: new FormControl(),
			val: undefined,
		},
		start: {
			control: new FormControl(),
			val: undefined,
		},
		end: {
			control: new FormControl(),
			val: undefined,
		},
	};

	dateMonth = new FormControl(moment());

	private subscriptions: Subscription[] = [];
	constructor(private activatedRoute: ActivatedRoute, private store: Store<AppState>, private serviceFormat: ServiceFormat, private templatePDFBilling: TemplatePDFBilling, private dialog: MatDialog, private router: Router, private service: BillingService, private layoutUtilsService: LayoutUtilsService, private subheaderService: SubheaderService, private cdr: ChangeDetectorRef, private http: HttpClient, private modalService: NgbModal) {}

	ngOnInit() {
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadBillingList();
				})
			)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		this.loadBillingList();

		// Set title to page breadCrumbs
		this.subheaderService.setTitle("Draft Billing");

		// Init DataSource
		this.dataSource = new BillingDatasource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(skip(1), distinctUntilChanged()).subscribe((res) => {
			this.billingResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First Load
		this.loadBillingList();
	}

	filterConfiguration(): any {
		const filter: any = {};
		// const searchText: string = this.searchInput.nativeElement.value.toLowerCase();
		const searchText = "";

		filter.invoice_no = `${searchText}`;
		return filter;
	}

	loadBillingList() {
		this.selection.clear();
		const queryParams = new QueryBillingModel(this.filterConfiguration(), this.sort.direction, this.sort.active, this.paginator.pageIndex + 1, this.paginator.pageSize);

		this.store.dispatch(new BillingGeneratedPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	deleteBilling(_item: BillingModel) {
		// tslint:disable-next-line:variable-name
		const _title = "Draft Billing Delete";
		// tslint:disable-next-line:variable-name
		const _description = "Are you sure to permanently delete this billing?";
		const _waitDesciption = "Draft Billing is deleting...";
		const _deleteMessage = `Draft Billing has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			this.store.dispatch(new BillingDeleted({ id: _item._id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	dateBillValue: any; // get Date Value to Validate generate bill
	changePeriode(event) {
		const getMonth = moment(event.value).format("MMMM"),
			getYear = moment(event.value).format("YYYY");
		this.periode_date = event.value;
		this.dateGenerateBilling = `${getMonth} ${getYear}`;
		this.dateBillValue = event.value._d.getDate();
	}

	/** Process Generate
	 * This is a popup for the progress of generating billing
	 * @param content
	 */
	processGenerate(content) {
		this.dialog.open(content, {
			data: {
				input: "",
			},
			maxWidth: "565px",
			minHeight: "375px",
			disableClose: true,
		});
	}

	/**
	 * auto
	 * @param cb
	 */
	auto(cb: Function) {
		this.loadinggenerate = true;

		const API_BILLING_URL = `${environment.baseAPI}/api/billing/generate-billing`;
		var data_url = this.http
			.post(`${API_BILLING_URL}`, {
				date: this.periode_date,
			})
			.subscribe(
				(res) => {
					if (res) {
						const message = `Auto Generate successfully has been added.`;
						this.layoutUtilsService.showActionNotification(message, MessageType.Create, 1000, true, true);
						this.checkProgressGenerate("success"); // Progress "success" generate PopUp
					}
				},
				(err) => {
					console.error(err);
					const message = "Error while adding billing | " + err.statusText;
					this.layoutUtilsService.showActionNotification(message, MessageType.Create, 1000, true, false);
					this.checkProgressGenerate("failed"); // Progress "failed" generate PopUp
					this.msgErrorGenerate = err.error.errorMessage; // Get message error from Back-end
				}
			);
	}

	/**
	 * Function to run progress on generating billing
	 * @param status status, to determine the feedback response from the back-end
	 */
	checkProgressGenerate(status: string) {
		this.isGenerateBilling = status;
	}

	/**
	 * Function to close the process generate dialog popup
	 */
	closePopUp() {
		this.dialog.closeAll();
		this.refresh(); // Refresh, load list billing and total billing
		this.dateGenerateBilling = ""; // Reset > dateGenerateBilling
		this.isGenerateBilling = ""; // Reset > isGenerateBilling
		this.msgErrorGenerate = ""; // Reset > msgErrorGenerate
	}

	/**
	 * onTabChange
	 * @param index
	 */
	onTabChange(index: number) {
		this.selectedIndex = index;
		if (index === 0) {
			this.router.navigate(["/billing"]);
		} else if (index === 1) {
			this.router.navigate(["/billing/process"]);
		}
	}

	openLarge(content) {
		this.modalService.open(content, {
			size: "lg",
			backdrop: "static",
		});
	}

	/**
	 * Refresh or Load Billing, and Total nominal Billing
	 */
	refresh() {
		this.loadBillingList(); // Load or refresh list Billing
	}

	/**
	 * function for checking unit type and generate pdf based on unit type
	 * @param id --> billing id
	 * @param print_action --> type template. ex: invoice or receipt
	 * template invoice for outstanding & bayar-kurang billing
	 * and receipt for full-payment & bayar-lebih
	 */
	cekBeforePrint(id) {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		this.getPDF(id);
	}

	getPDF(id: string) {
		const API_BILLING = `${environment.baseAPI}/api/billing`;
		this.http.get(`${API_BILLING}/template-pdf/${id}`).subscribe((res: any) => {
			// const label = res.result.invoice_no;
			// let template = this.templatePDFBilling.generatePDFTemplate(res.result);
			// pdfMake.createPdf(template).download(label);
			// this.loadBillingList();
		});
		//
	}

	/**
	 * _getPaymentClass
	 * @param status
	 * @returns
	 */
	_getPaymentClass(status: boolean) {
		return {
			chip: true,
			"chip--success": status,
			"chip--danger": !status,
		};
	}

	/**
	 * _getStatusPayCond
	 * @param status
	 * @param bill
	 * @returns
	 */
	_getStatusPayCond(status: string, bill) {
		if (status === "success") return "chip chip--full-payment";
		else if (status === "failed") return "chip chip--danger";
		else return "chip chip--parsial-lebih";
	}

	clearAllFilter() {
		this.dateMonth.setValue(moment());
		this.date.filter.control.setValue(undefined);

		this.valPayDate = "";

		this.checkClear = false;

		this.loadBillingList();
	}

	exportSuccess(_id: string) {
		// this.service.exportSuccess(_id);
	}
	exportFailed(_id: string) {
		// this.service.exportFailed(_id);
	}

	/**
	 * valueFilterStatus
	 * @param status
	 */
	valueFilterStatus(status: string) {}

	/**
	 * setMonthAndYear
	 * @param normalizedMonthAndYear
	 * @param datepicker
	 */
	setMonthAndYear(normalizedMonthAndYear, datepicker: MatDatepicker<Moment>) {
		let ctrlValue = this.dateMonth.value;
		console.log(normalizedMonthAndYear, "normalizedMonthAndYear");

		ctrlValue.month(normalizedMonthAndYear.month());
		ctrlValue.year(normalizedMonthAndYear.year());

		this.dateMonth.setValue(ctrlValue);
		let datVal = moment(this.dateMonth.value).format("L").split("/");
		let result = `${datVal[0]}/${datVal[2]}`;
		this.valPayDate = result;

		this.checkClear = true;

		this.loadBillingList();

		datepicker.close();
	}

	/**
	 *
	 * @returns
	 */
	totalAmount() {
		return "Rp. 0";
	}

	/**
	 * RpSign
	 * @param {*} value
	 * @returns
	 */
	RpSign(value) {
		// Pastikan nilai adalah angka
		if (typeof value !== "number") {
			value = Number(value);
		}

		// Format angka dengan toLocaleString
		return value.toLocaleString("id-ID", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
	}
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.billingResult.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.billingResult.length) {
			this.selection.clear();
		} else {
			this.billingResult.forEach((row) => this.selection.select(row));
		}
	}

	editBilling(id) {
		this.router.navigate(["edit", id], { relativeTo: this.activatedRoute });
	}

	viewBilling(id) {
		this.router.navigate(["view", id], { relativeTo: this.activatedRoute });
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}

	export() {
		this.service.exportExcel();
	}
}
