import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { Store, select } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { AppState } from "../../../../core/reducers";
import { SubheaderService, LayoutConfigService } from "../../../../core/_base/layout";
import { LayoutUtilsService, MessageType, QueryParamsModel } from "../../../../core/_base/crud";
import { BillingModel } from "../../../../core/billing/billing.model";
import { selectLastCreatedBillingId, selectBillingActionLoading, selectBillingById } from "../../../../core/billing/billing.selector";
import { BillingService } from "../../../../core/billing/billing.service";
import { ServiceFormat } from "../../../../core/serviceFormat/format.service";
import { disable } from "ol/rotationconstraint";
import { SelectionModel } from "@angular/cdk/collections";
import { QueryAccountBankModel } from "../../../../core/masterData/bank/accountBank/queryaccountBank.model";
import { AccountBankService } from "../../../../core/masterData/bank/accountBank/accountBank.service";
import { AccountGroupService } from "../../../../core/accountGroup/accountGroup.service";
import { QueryAccountGroupModel } from "../../../../core/accountGroup/queryag.model";
import moment from "moment";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Component({
	selector: "kt-view-billing",
	templateUrl: "./view-billing.component.html",
	styleUrls: ["./view-billing.component.scss"],
})
export class ViewBillingComponent implements OnInit, OnDestroy {
	codenum: any;
	billing: BillingModel;
	BillingId$: Observable<string>;
	selectedTab = 0;
	loading$: Observable<boolean>;
	billingForm: FormGroup;
	date1 = new FormControl(new Date());
	hasFormErrors = false;
	checker: boolean;
	loading: boolean;
	selection = new SelectionModel<BillingModel>(true, []);
	bankCtrl: FormControl = new FormControl();

	loadingData = {
		unit: false,
		overtimeRate: false,
	};

	billingName: string = "";
	billingIdEdit: string = "";
	selectedBank: string = "";
	faqs = [];
	features = [];
	selectedFeature: any[] = [];
	cekSelectPayment: boolean = true;
	selectedPay: any[] = [];
	bankResult: any[] = [];
	BankResultFiltered: any[] = [];
	viewBankResult = new FormControl();

	isCekBayarKurang: boolean = false;
	isCekBayarLebih: boolean = false;
	isCekCustom: boolean = false;
	isCekFullPayment: boolean = false;
	isPayCond: boolean = true;
	isBayarKurang: boolean = true;
	isBayarLebih: boolean = true;
	isCustom: boolean = true;
	isCustomTagihan: boolean = true;
	isShowSTagihan: boolean = true;
	isShowSPembayaran: boolean = true;
	isShowTagihanBilling: boolean = true;
	editButtonDisabled: boolean = false;
	isUsedGenerateVAT: boolean = false;

	unitResult: any[] = [];

	condAcct: boolean = false;

	paymentSelection: any = [
		{
			payment: "Full Payment",
			value: "full-payment",
		},
		{
			payment: "Pembayaran Kurang",
			value: "bayar-kurang",
		},
		{
			payment: "Pembayaran Lebih",
			value: "bayar-lebih",
		},
	];

	viewPaidToBank = new FormControl();
	paidToListResultFiltered = [];

	detailBilling = {};

	totalBillLeft: number = 0;

	overtimeRateResult = [];

	paymentValue: any;
	valueStatus: string = "outstanding";

	payment = {
		valid: false,
		target: {
			control: new FormControl(),
			val: undefined,
		},
		tagihanCustom: {
			control: new FormControl(),
			val: undefined,
		},
	};

	idParams: string;

	record = [];

	paymentType: any = [
		{
			payment: "Transfer",
			value: "Transfer",
		},
		{
			payment: "Cash",
			value: "Cash",
		},
		{
			payment: "Virtual Account",
			value: "Virtual Account",
		},
	];

	selectedOvertimeRate = [];
	selectedNoOvertimeRate = [];

	private subscriptions: Subscription[] = [];
	constructor(private activatedRoute: ActivatedRoute, private serviceFormat: ServiceFormat, private router: Router, private http: HttpClient, private billingFB: FormBuilder, private subheaderService: SubheaderService, private layoutUtilsService: LayoutUtilsService, private store: Store<AppState>, private service: BillingService, private layoutConfigService: LayoutConfigService, private bservice: AccountGroupService, private bankService: AccountBankService, private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.loading$ = this.store.pipe(select(selectBillingActionLoading));
		const routeSubscription = this.activatedRoute.params.subscribe((params) => {
			const id = params.id;
			if (id) {
				this.idParams = id;
				this.store.pipe(select(selectBillingById(id))).subscribe((res) => {
					if (res) {
						this.viewBankResult.setValue(`${res.bank}`);
						this.billingIdEdit = res._id;
						this.billing = res;
						this.totalBillLeft = res.totalBillLeft;
						this.checkPayTheBill(res);

						this.loadSendDetailBilling(res._id);

						this.initBilling();
					}
				});
			} else {
				this.billing = new BillingModel();
				this.billing.clear();
				this.initBilling();
			}
		});
		this.subscriptions.push(routeSubscription);
	}

	filterBank(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.bankResult.filter((option) => option.acctNo.toLowerCase().includes(filterValue) || option.acctName.toLowerCase().includes(filterValue));
	}

	initBilling() {
		this.createForm();
		this.checkPaymentStatus();
		this.loadCoaBank();
		this.loadRecordPayment();
	}

	clickGenerateNoVat() {
		const controls = this.billingForm.controls;
		const API_BILLING_URL = `${environment.baseAPI}/api/billing`;
		this.http.get(`${API_BILLING_URL}/generate-no-vat-empty/${this.idParams}`).subscribe(
			(res: any) => {
				if (res.data) {
					controls.vat_no.setValue(res.data);
					const message = `no.vat used successfully`;
					this.layoutUtilsService.showActionNotification(message);
					this.isUsedGenerateVAT = true;

					return;
				} else {
					const message = `vat no. not available`;
					this.layoutUtilsService.showActionNotification(message);
					return;
				}
			},
			(err) => {
				console.log(err);
				const message = `there was an error when generating no. vat`;
				this.layoutUtilsService.showActionNotification(message);
				return;
			}
		);
	}

	createForm() {
		this.billingForm = this.billingFB.group({
			unit2: [{ value: this.billing.unit2, disabled: false }],
			billed_to: [{ value: this.billing.billed_to, disabled: false }],
			paidDate: [{ value: this.billing.paidDate, disabled: false }, Validators.required],
			billing_number: [{ value: this.billing.billing_number, disabled: false }],
			bank: [{ value: this.billing.bank, disabled: false }, Validators.required],
			billing_date: [{ value: moment(new Date(this.billing.billing_date)).format("DD MMMM YYYY"), disabled: false }],
			due_date: [{ value: moment(new Date(this.billing.due_date)).format("DD MMMM YYYY"), disabled: false }],
			prevTotalBillLeft: [{ value: this.billing.previous_billing ? this.serviceFormat.rupiahFormatImprovement(this.billing.previous_billing.total_not_changed) : 0, disabled: false }],
			originalBill: [{ value: this.billing.originalBill ? this.serviceFormat.rupiahFormatImprovement(this.billing.originalBill) : 0, disabled: false }],
			arCard_balance: [{ value: this.billing.arCard_balance ? this.serviceFormat.rupiahFormatImprovement(this.billing.arCard_balance) : 0, disabled: false }],
			totalBilling: [{ value: this.billing.totalBilling ? this.serviceFormat.rupiahFormatImprovement(this.billing.totalBilling) : 0, disabled: false }],
			totalBillLeft: [{ value: this.billing.totalBillLeft ? this.serviceFormat.rupiahFormatImprovement(this.billing.totalBillLeft) : 0, disabled: false }],
			adminCharge: [{ value: this.billing.adminCharge ? this.serviceFormat.rupiahFormatImprovement(this.billing.adminCharge) : 0, disabled: false }],
			totalPaid: [{ value: this.billing.totalPaid ? this.serviceFormat.rupiahFormatImprovement(this.billing.totalPaid) : 0, disabled: false }],

			// Common Area Start
			common_area_area: [{ value: this.billing.common_area.area, disabled: false }],
			common_area_rate: [{ value: this.billing.common_area.rate ? this.serviceFormat.rupiahFormatImprovement(this.billing.common_area.rate) : 0, disabled: false }],
			common_area_pure_bill: [{ value: this.billing.common_area.pure_bill ? this.serviceFormat.rupiahFormatImprovement(this.billing.common_area.pure_bill) : 0, disabled: false }],
			// Common Area End

			// Iuran Anggota Start
			iuran_anggota_area: [{ value: this.billing.iuran_anggota.area, disabled: false }],
			iuran_anggota_rate: [{ value: this.billing.iuran_anggota.rate ? this.serviceFormat.rupiahFormatImprovement(this.billing.iuran_anggota.rate) : 0, disabled: false }],
			iuran_anggota_pure_bill: [{ value: this.billing.iuran_anggota.pure_bill ? this.serviceFormat.rupiahFormatImprovement(this.billing.iuran_anggota.pure_bill) : 0, disabled: false }],
			// Iuran Anggota End

			// Sinking Fund Start
			sinking_fund_area: [{ value: this.billing.sinking_fund.area, disabled: false }],
			sinking_fund_rate: [{ value: this.billing.sinking_fund.rate ? this.serviceFormat.rupiahFormatImprovement(this.billing.sinking_fund.rate) : 0, disabled: false }],
			sinking_fund_pure_bill: [{ value: this.billing.sinking_fund.pure_bill ? this.serviceFormat.rupiahFormatImprovement(this.billing.sinking_fund.pure_bill) : 0, disabled: false }],
			// Sinking Fund End

			// Service Charge Start
			service_charge_area: [{ value: this.billing.service_charge.area, disabled: false }],
			service_charge_rate: [{ value: this.billing.service_charge.rate ? this.serviceFormat.rupiahFormatImprovement(this.billing.service_charge.rate) : 0, disabled: false }],
			service_charge_pure_bill: [{ value: this.billing.service_charge.pure_bill ? this.serviceFormat.rupiahFormatImprovement(this.billing.service_charge.pure_bill) : 0, disabled: false }],
			// Service Charge End

			// Water Start
			water_start_meter: [{ value: this.billing.water.start_meter, disabled: false }],
			water_end_meter: [{ value: this.billing.water.end_meter, disabled: false }],
			water_usage_meter: [{ value: this.billing.water.usage, disabled: false }],
			water_usage_rate: [{ value: this.billing.water.usage_rate ? this.rupiahFormatImprovement(this.billing.water.usage_rate) : 0, disabled: false }],
			water_usage_price: [{ value: this.billing.water.usage_rate_total ? this.rupiahFormatImprovement(this.billing.water.usage_rate_total) : 0, disabled: false }],
			water_fixed_charge: [{ value: this.billing.water.fixed_charge ? this.rupiahFormatImprovement(this.billing.water.fixed_charge) : 0, disabled: false }],
			water_maintenance: [{ value: this.billing.water.maintenance ? this.rupiahFormatImprovement(this.billing.water.maintenance) : 0, disabled: false }],
			water_pure_bill: [{ value: this.billing.water.pure_bill ? this.rupiahFormatImprovement(this.billing.water.pure_bill) : 0, disabled: false }],
			// Water End
		});
	}

	goBackWithId() {
		const url = `/billing`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	refreshBilling(isNew: boolean = false, id: string = "") {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/billing/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	rupiahFormatImprovement(x) {
		// Float with accounting format
		if (x % 1 != 0) {
			if (x < 0) {
				let num2Str = x.toString();
				let spl = num2Str.split("");
				spl.shift();
				let joi = spl.join("");
				let xStr0 = joi.toString().split("."),
					dec0 = xStr0[1].substr(0, 2),
					Sisa = xStr0[0].length % 3,
					head = xStr0[0].substr(0, Sisa),
					body = xStr0[0].substr(Sisa).match(/\d{1,3}/g);

				if (body) {
					let separate = Sisa ? "." : "";
					return "(" + head + separate + body.join(".") + "," + dec0 + ")";
				}
				return "(" + xStr0[0] + "," + dec0 + ")";
			}
			let xStr0 = x.toString().split("."),
				dec0 = xStr0[1].substr(0, 2),
				Sisa = xStr0[0].length % 3,
				head = xStr0[0].substr(0, Sisa),
				body = xStr0[0].substr(Sisa).match(/\d{1,3}/g);

			if (body) {
				let separate = Sisa ? "." : "";
				return head + separate + body.join(".") + "," + dec0;
			}
			return xStr0[0] + "," + dec0;
		}

		if (x < 0) {
			let num2Str = x.toString();
			let spl = num2Str.split("");
			spl.shift();
			spl = spl.join("");
			let sisa = spl.length % 3,
				headValue = spl.substr(0, sisa),
				bodyValue = spl.substr(sisa).match(/\d{1,3}/g);

			if (bodyValue) {
				let separator = sisa ? "." : "";
				return "(" + headValue + separator + bodyValue.join(".") + ")";
			}
			return "(" + headValue + ")";
		}

		// Integer with accounting format
		let xStr = x.toString(),
			sisa = xStr.length % 3,
			headValue = xStr.substr(0, sisa),
			bodyValue = xStr.substr(sisa).match(/\d{1,3}/g);

		if (bodyValue) {
			let separator = sisa ? "." : "";
			return headValue + separator + bodyValue.join(".");
		}
		return headValue;
	}

	convertMinesToPlus(val) {
		const value = val;
		const regex = /\-\d/g;
		const validate = regex.test(value);

		if (validate) {
			const number = val;
			const str = number.toString();
			const result = str.replace("-", "");
			const resultNumber = parseFloat(result);
			return resultNumber;
		} else return parseFloat(val);
	}

	formatFloat(v: any): number {
		if (v === null || v === undefined) {
			return 0;
		}
		const value = typeof v === "string" ? v : v.toString();
		const formattedValue = value.replace(/[\.]+/g, "").replace(/[\,]+/g, ".");
		return parseFloat(formattedValue);
	}

	checkBankChoose() {
		const controls = this.billingForm.controls;

		if (controls.acctNo.value) this.condAcct = true;
		else this.condAcct = false;
	}

	_setBank(value) {
		const controls = this.billingForm.controls;
		controls.bankFromId.setValue(value._id);
		controls.bankFrom.setValue(value.acctName);
		controls.bankFromNo.setValue(value.aactNo);
		this.checkBankChoose();
	}

	onSubmit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.billingForm.controls;

		/** check form */
		if (!controls.adminCharge.value || !controls.bank.value) {
			const message = `Enter paid to bank`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
			return;
		}

		if (this.billingForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedBilling = this.prepareBilling();
		this.updateBilling(editedBilling, withBack);
	}

	prepareBilling(): BillingModel {
		const controls = this.billingForm.controls;
		const _billing = new BillingModel();
		_billing.clear();

		_billing.pay_the_bill = this.selectedOvertimeRate;
		_billing.paidDate = controls.paidDate.value;
		_billing.adminCharge = controls.adminCharge.value ? this.formatFloat(controls.adminCharge.value) : controls.adminCharge.value;
		_billing.totalPaid = controls.totalPaid.value ? this.formatFloat(controls.totalPaid.value) : controls.totalPaid.value;
		_billing.bank = controls.bank.value;
		_billing._id = this.billing._id;
		_billing.selectedBank = this.selectedBank;

		return _billing;
	}

	updateBilling(_billing: BillingModel, withBack: boolean = false) {
		const addSubscription = this.service.updateBilling(_billing).subscribe(
			(res) => {
				const message = `Billing successfully has been saved.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
				const url = `/billing`;
				this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
			},
			(err) => {
				console.error(err);
				const message = "Error while adding billing | " + err.statusText;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);
			}
		);
		this.subscriptions.push(addSubscription);
	}

	getComponentTitle() {
		let result = "View Draft Billing Residential";
		return result;
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}

	/**
	 * toggleSubMenu
	 * @param item
	 */
	toggleSubMenu(item: any) {
		item.showSubMenu = !item.showSubMenu;
	}

	/**
	 * toggleNestedSubMenu
	 * @param parentSubMenu
	 * @param index
	 */
	toggleNestedSubMenu(parentSubMenu: any, index: number) {
		parentSubMenu.submenu[index].showSubMenu = !parentSubMenu.submenu[index].showSubMenu;
	}

	/**
	 * toggleNestedSubMenu
	 * @param parentSubMenu
	 * @param index
	 */
	toggleNestedSubMenu2(parentSubMenu: any, index: number, indexNested: number) {
		parentSubMenu.submenu[index].submenu[indexNested].showSubMenu = !parentSubMenu.submenu[index].submenu[indexNested].showSubMenu;
	}

	/**
	 * handleBillingNameChange
	 * @param event
	 */
	handleBillingNameChange(event: any): void {
		this.billingName = event.target.value;
	}

	/**
	 * shouldCheckboxBeChecked
	 * @param faq
	 * @returns
	 */
	shouldCheckboxBeChecked(faq: any): boolean {
		if (this.billing._id) {
			// return faq.billing.includes(this.billing.billing);
		} else return false;
	}

	/**
	 * _getStatusClass
	 * @param status
	 * @param date
	 * @returns
	 */
	_getStatusClass() {
		if (this.valueStatus == "full-payment") return "chip chip--full-payment";
		else if (this.valueStatus == "bayar-kurang") return "chip chip--parsial-kurang";
		else return "chip chip--outstanding";
	}

	/**
	 * shouldFeatureCheckboxBeChecked
	 * @param faq
	 * @returns
	 */
	shouldFeatureCheckboxBeChecked(featureName: string, type: string): boolean {
		const controls = this.billingForm.controls;
		const billingName = controls.billing.value;

		const findSelectedFeature = this.features.find((data) => data.name === featureName);
		const findSelectedFeatureData = this.selectedFeature.find((data) => data.name === featureName);

		if (findSelectedFeatureData) {
			const findBillingAccess = findSelectedFeatureData.billing_access.find((data) => data.billing === billingName);

			if (findBillingAccess) {
				if (type === "add") return findBillingAccess.add;
				else if (type === "edit") return findBillingAccess.edit;
				else if (type === "view") return findBillingAccess.view;
				else if (type === "delete") return findBillingAccess.delete;
				else if (type === "full-access") return findBillingAccess.fullAccess;
				else return false;
			} else return false;
		} else if (findSelectedFeature) {
			const findBillingAccess = findSelectedFeature.billing_access.find((data) => data.billing === billingName);

			if (findBillingAccess) {
				if (type === "add") return findBillingAccess.add;
				else if (type === "edit") return findBillingAccess.edit;
				else if (type === "view") return findBillingAccess.view;
				else if (type === "delete") return findBillingAccess.delete;
				else if (type === "full-access") return findBillingAccess.fullAccess;
				else return false;
			} else return false;
		} else return false;
	}

	/**
	 * handleCheckboxDepth1
	 * @param event
	 * @param faqIndex
	 */
	handleCheckboxDepth1(event: any, faqIndex: number): void {
		const controls = this.billingForm.controls;
		if (event.checked) {
			this.addBillingToHierarchy(this.faqs[faqIndex], controls.billing.value);
		} else {
			this.removeBillingFromHierarchy(this.faqs[faqIndex], controls.billing.value);
		}
	}

	/**
	 * handleCheckboxDepth2
	 * @param event
	 * @param faqIndex
	 * @param submenuIndex
	 */
	handleCheckboxDepth2(event: any, faqIndex: number, submenuIndex: number): void {
		const controls = this.billingForm.controls;
		if (event.checked) {
			this.addBillingToHierarchy(this.faqs[faqIndex].submenu[submenuIndex], controls.billing.value);
		} else {
			this.removeBillingFromHierarchy(this.faqs[faqIndex].submenu[submenuIndex], controls.billing.value);
		}
	}

	/**
	 * handleCheckboxDepth3
	 * @param event
	 * @param faqIndex
	 * @param submenuIndex
	 * @param nestedIndex
	 */
	handleCheckboxDepth3(event: any, faqIndex: number, submenuIndex: number, nestedIndex: number): void {
		const controls = this.billingForm.controls;
		if (event.checked) {
			this.addBillingToHierarchy(this.faqs[faqIndex].submenu[submenuIndex].submenu[nestedIndex], controls.billing.value);
		} else {
			this.removeBillingFromHierarchy(this.faqs[faqIndex].submenu[submenuIndex].submenu[nestedIndex], controls.billing.value);
		}
	}

	/**
	 * handleCheckboxDepth4
	 * @param event
	 * @param faqIndex
	 * @param submenuIndex
	 * @param nestedIndex
	 */
	handleCheckboxDepth4(event: any, faqIndex: number, submenuIndex: number, nestedIndex: number, nestedIndexAgain: number): void {
		const controls = this.billingForm.controls;
		if (event.checked) {
			this.addBillingToHierarchy(this.faqs[faqIndex].submenu[submenuIndex].submenu[nestedIndex].submenu[nestedIndexAgain], controls.billing.value);
		} else {
			this.removeBillingFromHierarchy(this.faqs[faqIndex].submenu[submenuIndex].submenu[nestedIndex].submenu[nestedIndexAgain], controls.billing.value);
		}
	}

	checkPaymentStatus() {
		if (this.overtimeRateResult.length === 5) this.valueStatus = "outstanding";
		else if (!this.overtimeRateResult.length) this.valueStatus = "full-payment";
		else this.valueStatus = "bayar-kurang";
	}

	/**
	 * handleCheckboxFeatureAccess
	 * @param event
	 * @param feature
	 * @param accessType
	 */
	handleCheckboxFeatureAccess(event: any, feature: string, accessType: string): void {
		const controls = this.billingForm.controls;
		const index = this.selectedFeature.findIndex((item) => item.name === feature);

		if (event.checked) {
			if (index !== -1) {
				if (accessType === "full-access") {
					this.selectedFeature[index].billing_access[0]["add"] = true;
					this.selectedFeature[index].billing_access[0]["edit"] = true;
					this.selectedFeature[index].billing_access[0]["view"] = true;
					this.selectedFeature[index].billing_access[0]["delete"] = true;
					this.selectedFeature[index].billing_access[0]["fullAccess"] = true;
				} else {
					this.selectedFeature[index].billing_access[0][accessType] = true;
				}
			} else if (accessType === "full-access") {
				let dataFindBillingAccess = null;
				const findFeatureAccess = this.features.find((data) => data.name == feature);
				if (findFeatureAccess) {
					const findBillingAccess = findFeatureAccess.billing_access.find((data) => data.billing == controls.billing.value);
					if (findBillingAccess) dataFindBillingAccess = findBillingAccess;
				}
				const newFeature = {
					name: feature,
					billing_access: [
						{
							billing: controls.billing.value,
							edit: true,
							view: true,
							add: true,
							delete: true,
							fullAccess: true,
						},
					],
				};
				newFeature.billing_access[0]["fullAccess"] = true;
				this.selectedFeature.push(newFeature);
			} else {
				let dataFindBillingAccess = null;
				const findFeatureAccess = this.features.find((data) => data.name == feature);
				if (findFeatureAccess) {
					const findBillingAccess = findFeatureAccess.billing_access.find((data) => data.billing == controls.billing.value);
					if (findBillingAccess) dataFindBillingAccess = findBillingAccess;
				}
				const newFeature = {
					name: feature,
					billing_access: [
						{
							billing: controls.billing.value,
							edit: dataFindBillingAccess ? dataFindBillingAccess.edit : false,
							view: dataFindBillingAccess ? dataFindBillingAccess.view : false,
							add: dataFindBillingAccess ? dataFindBillingAccess.add : false,
							delete: dataFindBillingAccess ? dataFindBillingAccess.delete : false,
							fullAccess: dataFindBillingAccess ? dataFindBillingAccess.fullAccess : false,
						},
					],
				};
				newFeature.billing_access[0][accessType] = true;
				this.selectedFeature.push(newFeature);
			}
		} else {
			if (index !== -1) {
				if (accessType === "full-access") {
					this.selectedFeature[index].billing_access[0]["add"] = false;
					this.selectedFeature[index].billing_access[0]["edit"] = false;
					this.selectedFeature[index].billing_access[0]["view"] = false;
					this.selectedFeature[index].billing_access[0]["delete"] = false;
					this.selectedFeature[index].billing_access[0]["fullAccess"] = false;
				} else {
					this.selectedFeature[index].billing_access[0][accessType] = false;
				}
			} else if (accessType === "full-access") {
				let dataFindBillingAccess = null;
				const findFeatureAccess = this.features.find((data) => data.name == feature);
				if (findFeatureAccess) {
					const findBillingAccess = findFeatureAccess.billing_access.find((data) => data.billing == controls.billing.value);
					if (findBillingAccess) dataFindBillingAccess = findBillingAccess;
				}
				const newFeature = {
					name: feature,
					billing_access: [
						{
							billing: controls.billing.value,
							edit: false,
							view: false,
							add: false,
							delete: false,
							fullAccess: false,
						},
					],
				};
				newFeature.billing_access[0]["fullAccess"] = false;
				this.selectedFeature.push(newFeature);
			} else {
				let dataFindBillingAccess = null;
				const findFeatureAccess = this.features.find((data) => data.name == feature);
				if (findFeatureAccess) {
					const findBillingAccess = findFeatureAccess.billing_access.find((data) => data.billing == controls.billing.value);
					if (findBillingAccess) dataFindBillingAccess = findBillingAccess;
				}
				const newFeature = {
					name: feature,
					billing_access: [
						{
							billing: controls.billing.value,
							edit: dataFindBillingAccess ? dataFindBillingAccess.edit : false,
							view: dataFindBillingAccess ? dataFindBillingAccess.view : false,
							add: dataFindBillingAccess ? dataFindBillingAccess.add : false,
							delete: dataFindBillingAccess ? dataFindBillingAccess.delete : false,
							fullAccess: dataFindBillingAccess ? dataFindBillingAccess.fullAccess : false,
						},
					],
				};
				newFeature.billing_access[0][accessType] = false;
				this.selectedFeature.push(newFeature);
			}
		}
	}

	loadSendDetailBilling(id: string) {
		this.service.getSendDetailBilling(id).subscribe((res) => {
			this.detailBilling = res.data;
			this.cd.markForCheck();
		});
	}

	onSelect(e: any) {
		const controls = this.billingForm.controls;
		let validate = this.selectedOvertimeRate.find((data) => data.type === e.type);
		if (validate) return;
		this.selectedOvertimeRate.push(e);
		this.selectedNoOvertimeRate.push(e.type);
		this.overtimeRateResult = this.overtimeRateResult.filter((item) => item.type !== e.type);

		let valueTotalPaid = controls.totalPaid.value ? this.formatFloat(controls.totalPaid.value) : 0;

		if (e.type === "Common Area") {
			valueTotalPaid += this.billing.common_area.total;
			this.totalBillLeft -= this.billing.common_area.total;
		} else if (e.type === "Iuran Anggota") {
			valueTotalPaid += this.billing.iuran_anggota.total;
			this.totalBillLeft -= this.billing.iuran_anggota.total;
		} else if (e.type === "Sinking Fund") {
			valueTotalPaid += this.billing.sinking_fund.total;
			this.totalBillLeft -= this.billing.sinking_fund.total;
		} else if (e.type === "Service Charge") {
			valueTotalPaid += this.billing.service_charge.total;
			this.totalBillLeft -= this.billing.service_charge.total;
		} else if (e.type === "Water") {
			valueTotalPaid += this.billing.water.total;
			this.totalBillLeft -= this.billing.water.total;
		}

		// Handle Total Paid Start
		controls.totalPaid.setValue(this.rupiahFormatImprovement(valueTotalPaid));
		// Handle Total Paid End

		this.viewPaidToBank.setValue("");
		controls.adminCharge.setValue("");

		controls.totalBillLeft.setValue(this.rupiahFormatImprovement(this.totalBillLeft));
		this.checkPaymentStatus();

		this.cd.markForCheck();
	}

	// Fungsi deleteList yang diperbarui
	deleteList(id: string) {
		const controls = this.billingForm.controls;

		const itemIndex = this.selectedOvertimeRate.findIndex((item) => item.type === id);
		if (itemIndex >= 0) {
			const [item] = this.selectedOvertimeRate.splice(itemIndex, 1);
			this.selectedNoOvertimeRate = this.selectedNoOvertimeRate.filter((type) => type !== id);
			this.overtimeRateResult.push(item);

			let valueTotalPaid = controls.totalPaid.value ? this.formatFloat(controls.totalPaid.value) : 0;

			if (id === "Common Area") {
				valueTotalPaid -= this.billing.common_area.total;
				this.totalBillLeft += this.billing.common_area.total;
			} else if (id === "Iuran Anggota") {
				valueTotalPaid -= this.billing.iuran_anggota.total;
				this.totalBillLeft += this.billing.iuran_anggota.total;
			} else if (id === "Sinking Fund") {
				valueTotalPaid -= this.billing.sinking_fund.total;
				this.totalBillLeft += this.billing.sinking_fund.total;
			} else if (id === "Service Charge") {
				valueTotalPaid -= this.billing.service_charge.total;
				this.totalBillLeft += this.billing.service_charge.total;
			} else if (id === "Water") {
				valueTotalPaid -= this.billing.water.total;
				this.totalBillLeft += this.billing.water.total;
			}

			// Handle Total Paid Start
			controls.totalPaid.setValue(this.rupiahFormatImprovement(valueTotalPaid));
			// Handle Total Paid End

			this.viewPaidToBank.setValue("");
			controls.adminCharge.setValue("");

			controls.totalBillLeft.setValue(this.rupiahFormatImprovement(this.totalBillLeft));
			this.checkPaymentStatus();

			this.cd.markForCheck();
		}
	}

	rupiahGetComma(split: any, rupiah: any) {
		return split[1] != undefined ? (split[1][1] != undefined ? rupiah + "," + split[1][0] + split[1][1] : split[1] != "" ? rupiah + "," + split[1][0] : rupiah + "," + split[1]) : rupiah;
	}

	onSelectionChange(event: any): void {
		if (!this.selectedOvertimeRate.length) {
			const message = `Please enter your payment first`;
			this.layoutUtilsService.showActionNotification(message);
			return;
		}

		const controls = this.billingForm.controls;
		const selectedItem = event.value;

		controls.bank.setValue(selectedItem._id);
		this.selectedBank = `${selectedItem.acctNo} ${selectedItem.acctName}`;
		const valueTotalPaid = controls.totalPaid.value ? this.formatFloat(controls.totalPaid.value) : 0;
		const resultTotalPaid = Math.round(valueTotalPaid * (selectedItem.value / 100));
		controls.adminCharge.setValue(this.rupiahFormatImprovement(resultTotalPaid));
	}

	/**
	 * changeAmount
	 * @param event
	 * @param id
	 */
	changeAmount(event: any, id: any) {
		let controls = this.billingForm.controls;
		let value = event.target.value;

		var number_string = value.replace(/[^,\d]/g, "").toString(),
			split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		let separator;
		if (ribuan) {
			separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		rupiah = this.rupiahGetComma(split, rupiah);

		const totalTagihan: any = 0;

		let inputValue = this.formatFloat(rupiah);

		if (id == "payment_amount" && this.paymentValue === "bayar-kurang") {
			controls.payment_amount.setValue(rupiah);
			let sisa_tagihan: any = totalTagihan - inputValue;
			controls.sisa_tagihan.setValue(this.rupiahFormatImprovement(sisa_tagihan));
		} else if (id == "payment_amount" && this.paymentValue === "bayar-lebih") {
			controls.payment_amount.setValue(rupiah);
			let sisa_pembayaran: any = inputValue - totalTagihan;
			controls.sisa_pembayaran.setValue(this.rupiahFormatImprovement(sisa_pembayaran));
		}
		return rupiah;
	}

	checkPayTheBill(billing: BillingModel) {
		if (billing.common_area.total > 0)
			this.overtimeRateResult.push({
				type: "Common Area",
			});
		if (billing.iuran_anggota.total > 0)
			this.overtimeRateResult.push({
				type: "Iuran Anggota",
			});
		if (billing.sinking_fund.total > 0)
			this.overtimeRateResult.push({
				type: "Sinking Fund",
			});
		if (billing.service_charge.total > 0)
			this.overtimeRateResult.push({
				type: "Service Charge",
			});
		if (billing.water.total > 0)
			this.overtimeRateResult.push({
				type: "Water",
			});
	}

	loadCoaBank() {
		this.service.getPaidTo().subscribe((res) => {
			this.paidToListResultFiltered = res.data;
		});
	}

	loadRecordPayment() {
		this.service.getRecordPayment(this.billing._id).subscribe((res) => {
			this.record = res.data;
		});
	}

	/**
	 * toCurrency
	 * @param event
	 * @param id
	 * @returns
	 */
	toCurrency(event: any) {
		const controls = this.billingForm.controls;

		let value = event.target.value;

		let number_string = value.replace(/[^-\d,]/g, "").toString(),
			isNegative = false;

		if (number_string.startsWith("-")) {
			isNegative = true;
			number_string = number_string.substr(1);
		}

		let split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		let separator;
		if (ribuan) {
			separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		if (split.length > 1) {
			rupiah += "," + split[1];
		}

		if (isNegative) {
			rupiah = "-" + rupiah;
		}

		controls.price.setValue(rupiah);
		return rupiah;
	}

	private addBillingToHierarchy(item: any, billing: string): void {
		if (item.billing.indexOf(billing) === -1) {
			item.billing.push(billing);
		}
	}

	private removeBillingFromHierarchy(item: any, billing: string): void {
		const billingIndex = item.billing.indexOf(billing);
		if (billingIndex !== -1) {
			item.billing.splice(billingIndex, 1);
		}
	}
}
