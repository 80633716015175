import { BaseModel } from "../_base/crud";

export class BlockgroupModel extends BaseModel {
	_id: string;
	grpnm: string;
	// prntid: string;
	crtdate: string;
	upddate: string;
	companyName: string;
	address: string;
	postCode: string;
	hotline: string;
	hotline2: string;
	email:string;
	npwp: string;
	nib: string;
	isPpn: boolean;
	note: string;
	color: string;
	mcolor: string;
	structure: any;
	countUpdate: number;
	data: any;
	periodeBilling: any;
	billStatus: any;
	billstatus: any;
	isDecimal: any;

	clear(): void {
		this._id = undefined;
		this.grpnm = "";
		// this.prntid = "";
		this.companyName = "";
		this.address = "";
		this.postCode = "";
		this.hotline = "";
		this.hotline2 = "";
		this.npwp = "";
		this.nib = "";
		this.isPpn = false;
		this.note = "";
		this.color = "";
		this.mcolor = "";
		this.structure = undefined;
		this.countUpdate = 0;

		this.crtdate = "";
		this.upddate = "";
		this.data = undefined;
		this.periodeBilling = undefined;
		this.isDecimal = undefined;
		this.billstatus = undefined;
		this.billStatus = undefined;
	}
}
