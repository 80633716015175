import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import moment from "moment";
import { ServiceFormat } from "../serviceFormat/format.service";

interface ElectricityBillingModel {
	usage: {
		start: number;
		end: number;
		used: number;
	};
	price: {
		used: string;
		amount: string;
		serviceCharge: string;
		PJU: string;
		loss: string;
		total: string;
	};
	serviceCharge: number;
	PJU: number;
	loss: number;
	taxPercent: number;
	totalTax: string;
	adminFee: string;
	adminFeeAfterTax: string;
	allPowerAmount: string;
}

interface WaterBillingModel {
	dirty: number;
	usage: {
		start: number;
		end: number;
		used: number;
	};
	price: {
		used: string;
		amount: string;
		admin: string;
		maintenance: string;
		dirty: string;
		abodemen: string;
		admBank: string;
		total: string;
	};
	taxPercent: number;
	totalTax: string;
	adminFee: string;
	adminFeeAfterTax: string;
	allWaterAmountAfterFeeAndTax: string;
}

interface IPLBillingModel {
	period: string;
	usage: number;
	price: {
		used: {
			serviceCharge: string;
			sinkingFund: string;
		};
		admBank: string;
		ipl: string;
		total: string;
	};
	taxPercent: number;
	totalTax: string;
	allIplAfterTax: string;
}

interface GalonBillingModel {
	unitCode: string;
	brand: string;
	rate: string;
	quantity: string;
	eachTotal: string;
	totalQty: number;
	totalTr: string;
	taxPercentage: number;
	totalTax: string;
	totalTrAfterTax: string;
}
interface PaymentBank {
	acctNo: string;
	acctName: string;
}

interface FeeAmount {
	acctNo: string;
	acctName: string;
}

interface IncFrmCollect {
	acctNo: string;
	acctName: string;
}
interface DataUnitObj {
	block: string;
	nmunit: string;
	floor: string;
}

interface BillingPDFContent {
	logo: string;
	logo2: string;
	startMonth: string;
	endMonth: string;

	name: string;
	unit: string;
	block: string;
	address: string;
	invoiceNo: string;
	invoiceDate: string;
	invoiceDue: string;
	period: string;

	va_ipl: string;
	va_water: string;
	va_power: string;
	va_gas: string;
	va_parking: string;
	va_utility: string;

	isSewa: boolean;

	totalAllGrandTotal: string;
	isReceipt: string;

	allIplInWord: string;
	allWaterInWord: string;

	totalIpl: string;

	dateReceipt: string;

	paidDateBilling: string;

	dataUnitObj: DataUnitObj;

	paymentBankObj: PaymentBank;
	feeAmountObj: FeeAmount;
	incFrmCollectObj: IncFrmCollect;

	feeAmountPriceWTR: string;

	electricity: ElectricityBillingModel;
	water: WaterBillingModel;
	IPL: IPLBillingModel;
	galon: GalonBillingModel;

	grandTotal: string;
	totalInWord: string;

	originalBill: string;
	originalBillInWord: string;
}

@Injectable({
	providedIn: "root",
})
export class TemplatePDFBilling {
	constructor(private http: HttpClient, private serviceFormat: ServiceFormat) {}

	generatePDFTemplate(content?: any) {
		return content.template;
	}
}
